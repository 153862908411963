//
// ecommerce.scss
//

// product

.search-box {
  .form-control {
    border-radius: 30px;
    padding-left: 40px;
  }
  .search-icon {
    font-size: 16px;
    position: absolute;
    left: 13px;
    top: 0;
    line-height: 38px;
  }
}

.categories-list {
  padding: 4px 0;
  li {
    a {
      display: block;
      padding: 4px 12px;
      color: $body-color;
    }

    &.active {
      a {
        color: $primary;
      }
    }
  }
}

.StyledCarousel {
  li {
    width: 150px !important;
    margin-right: 20px;
  }
}

product-boxes {
  border: 1px solid $border-color;
  overflow: hidden;
  margin-top: 20px;
  cursor: pointer;
  width: 11%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  height: fit-content;

  &:hover {
    box-shadow: $box-shadow;

    .product-color {
      visibility: visible;
    }
  }
}

@media (max-width: 1400px) {
  .product-boxes {
    width: 15%;
  }
}

@media (min-width: 1400px) {
  .product-boxes {
    width: 11%;
  }
}

@media (max-width: 1024px) {
  .product-boxes {
    width: 20%;
  }
}
@media (max-width: 580px) {
  .product-boxes {
    width: 50%;
  }
}

@media (max-width: 380px) {
  .product-boxes {
    width: 50%;
  }
}

// product box

.product-box {
  border: 1px solid $border-color;
  transition: all 0.4s;
  overflow: hidden;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    box-shadow: $box-shadow;

    .product-content {
      transform: translateY(-30px);
    }

    .product-color {
      visibility: visible;
    }
  }
}

.product-img {
  position: relative;

  .product-wishlist {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9;
    a {
      display: inline-block;
      width: 40px;
      height: 40px;
      border: 2px solid $gray-300;
      line-height: 38px;
      border-radius: 50%;
      text-align: center;
      color: $gray-500;
    }
  }

  .product-content {
    position: relative;
    transition: all 0.2s;
  }

  .product-color {
    position: absolute;
    left: 0;
    right: 0;
    margin: 16px auto 0;
    visibility: hidden;
  }

  // product-detail

  .product-detail {
    .nav-pills {
      .nav-link {
        margin-bottom: 7px;
        &.active {
          background-color: $gray-300;
        }

        .tab-img {
          width: 5rem;
        }
      }
    }

    .product-img {
      padding: 24px;
    }
  }

  .product-desc-list {
    li {
      padding: 2px 0px;
    }
  }

  .product-desc-color {
    a {
      display: inline-block;
      text-align: center;
      color: $body-color;
      margin: 4px;
      border: 2px solid $border-color;
      border-radius: 4px;

      &.active,
      &:hover {
        color: $primary;
        border-color: $primary !important;
      }
    }
  }

  .product-review-link {
    .list-inline-item {
      a {
        color: $gray-600;
        padding: 4px 8px;
        font-size: 15px;
      }
    }
  }

  // ecommerce cart

  .product-cart-touchspin {
    border: 1px solid $input-border-color;
    background-color: $input-bg;
    border-radius: $border-radius;
    .form-control {
      border-color: transparent;
      height: 32px;
    }

    .input-group-btn .btn {
      background-color: transparent !important;
      border-color: transparent !important;
      color: $primary !important;
      font-size: 16px;
      padding: 3px 12px;
      box-shadow: none;
    }
  }

  // ecommerce checkout

  .shipping-address {
    box-shadow: none;
    &.active {
      border-color: $primary !important;
    }
  }
}
